import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import H3 from "./H3";
import {StaticImage} from "gatsby-plugin-image";
import fonts from "../../styles/fonts";

const Title = styled.div`
  max-width: 320px;
  margin: auto;

  @media (min-width: ${breakpoints.lg}) {
    max-width: none;
  }
`;

const SpecsContainer = styled.div`
  text-align: center;
  margin-top: 28px;
  margin-bottom: 90px;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 28px;
    margin-bottom: 120px;
  }
`;

const Items = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 40px;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    margin-top: 72px;
  }
`;

const Item = styled.div`
  margin-bottom: 40px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`;

const ItemImage = styled.div`
  width: 184px;
  height: 184px;
  margin: 0 auto 32px auto;
  
  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;

    > div {
      height: 100%;
    }
  }
`;

const ItemContent = styled.div`
  min-height: 72px;
  
  h4 {
    ${fonts.gloryBold};
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 8px;
  }
  
  p {
    ${fonts.gloryRegular};
    font-size: 17px;
    line-height: 24px;
    margin: auto;
    max-width: 260px;
    min-height: 72px;
  }
`;

const Specs = () => {
  return (
    <SpecsContainer>
      <Title>
        <H3>How to...</H3>
      </Title>
      <Items>
        <Item>
          <ItemImage>
            <StaticImage objectFit={"contain"}
                         src={"../../resources/img/home/play.png"}
                         alt={"Play"} />
          </ItemImage>
          <ItemContent>
            <h4>Play</h4>
            <p>Only those who purchase a unique Panda Dynasty NFT may participate.</p>
          </ItemContent>
        </Item>
        <Item>
          <ItemImage>
            <StaticImage objectFit={"contain"}
                         src={"../../resources/img/home/panda-passes-2.png"}
                         alt={"Puzzle"} />
          </ItemImage>
          <ItemContent>
            <h4>Get passes</h4>
            <p>Play games and solve puzzles. This will unlock Panda Passes that will be airdropped into your wallet.</p>
          </ItemContent>
        </Item>
        <Item>
          <ItemImage>
            <StaticImage objectFit={"contain"}
                         src={"../../resources/img/home/chest.png"}
                         alt={"Map"} />
          </ItemImage>
          <ItemContent>
            <h4>Earn</h4>
            <p>Be one of the first to connect your Metamask wallet into our bamboo chest!</p>
          </ItemContent>
        </Item>
      </Items>
    </SpecsContainer>
  )
};

export default Specs;
