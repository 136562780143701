import * as React from "react"
import styled from "styled-components"
import H1 from "./H1";
import breakpoints from "../../styles/breakpoints";
import heroBackground from "../../resources/img/background/home-hero.png";
import heroBackgroundMobile from "../../resources/img/background/home-hero-mobile.png";
import Nav from "../Core/Hero/Nav";
import Logo from "./Logo";
import ButtonLink from "./ButtonLink";

const HeroContainer = styled.div`
  width: 100%;
  height: 690px;
  position: relative;
  background: url(${heroBackgroundMobile}) no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  overflow: hidden;
  margin-bottom: 88px;
  
  @media (min-width: ${breakpoints.sm}) {
    background: url(${heroBackground}) no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 0;
    height: 600px;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    height: 640px;
  }
  
  @media (min-width: ${breakpoints.xl}) {
    height: 720px;
  }
`;

const HeroContent = styled.div`
  text-align: center;
  padding: 0;

  @media (min-width: ${breakpoints.xl}) {
    padding: 0 75px;
  }
`;

const HeroText = styled.div`
  width: 100%;
  max-width: 920px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 200px 0 0 42px;
  position: relative;
  
  @media (min-width: ${breakpoints.sm}) {
    padding: 42px 0 0 42px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (min-width: ${breakpoints.md}) {
    
  }
  
  @media (min-width: ${breakpoints.lg}) {
    padding: 0;
  }
`;

const HeroTextContainer = styled.div`
  max-width: 264px;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 420px;
  }
  
  @media (min-width: ${breakpoints.xl}) {
    max-width: 420px;
  }
`;

const DesktopOnly = styled.div`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }
`;

const Hero = () => {

  return (
    <HeroContainer>
      <HeroContent>
        <Nav/>
        <Logo />
      </HeroContent>
      <HeroText>
        <HeroTextContainer>
          <H1>Join the hunt and open the treasure chest!</H1>
          <DesktopOnly>
            <ButtonLink href={"https://pandadynasty.io/login"} blank={false}>
              Start the Hunt
            </ButtonLink>
          </DesktopOnly>
        </HeroTextContainer>
      </HeroText>
    </HeroContainer>
  )
}

export default Hero;
