import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import logoHome from "../../resources/img/home/logo-home.svg";
import {Link} from "gatsby";

const LogoImage = styled.img`
  margin-bottom: 0;
  height: 72px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 80px;
  }
`;

const Logo = () => {
  return (
    <Link to={"/"}>
      <LogoImage src={logoHome} alt={"Panda Dynasty"}/>
    </Link>
  )
}

export default Logo;
