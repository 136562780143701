import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";

const StyledH1 = styled.h1`
  ${fonts.gloryExtraBold};
  font-size: 50px;
  line-height: 60px;

  @media (min-width: ${breakpoints.xl}) {
    font-size: 64px;
    line-height: 64px;
  }
`;

const H1 = ({children}) => {
  return (
    <StyledH1>
      {children}
    </StyledH1>
  )
};

export default H1;

