import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import ButtonLink from "./ButtonLink";
import frameDesktop from "../../resources/img/home/treasure-bamboo-desktop.png";
import frameMobile from "../../resources/img/home/treasure-bamboo-mobile.png";
import colors from "../../styles/colors";

const BambooFrame = styled.div`
  width: 270px;
  height: 430px;
  position: relative;
  background: url(${frameMobile}) no-repeat;
  background-size: cover;
  margin: 0 auto 30px auto;

  @media (min-width: ${breakpoints.lg}) {
    background: url(${frameDesktop}) no-repeat;
    background-size: 100% auto;
    width: 100%;
    height: 390px;
    margin-bottom: 100px;
  }
`;

const TreasureContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  color: ${colors.grey};

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 90px;
  }
`;

const TreasureTitle = styled.div`
  ${fonts.gloryBold};
  font-size: 32px;
  line-height: 42px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 70px;
    line-height: 70px;
  }
`;

const TreasureItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  width: 100%;
  align-items: center;

  @media (min-width: ${breakpoints.lg}) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 32px;
    padding: 0 88px;
  }
`;

const TreasureItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 168px;
  text-align: center;
  
  span {
    margin-bottom: 4px;
  }
`;

const TextSmall = styled.span`
  ${fonts.gloryBold};
  font-size: 15px;
  line-height: 26px;
  height: 15px;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    font-size: 18px;
    line-height: 26px;
  }
`;

const TextLarge = styled.span`
  font-size: 24px;
  line-height: 32px;
  ${fonts.gloryBold};
  height: 30px;

  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    font-size: 50px;
    line-height: 50px;
  }
`;

const TextMedium = styled.span`
  font-size: 20px;
  line-height: 20px;
  ${fonts.gloryBold};
  max-width: 100px;
  height: 20px;
  
  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    font-size: 24px;
    line-height: 30px;
  }
`;

const Plus = styled.span`
  font-size: 24px;
  line-height: 32px;
  ${fonts.gloryBold};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 24px;
  
  @media (min-width: ${breakpoints.lg}) {
    height: auto;
    font-size: 50px;
    line-height: 50px;
  }
`;

const Treasure = () => {
  return (
    <BambooFrame>
      <TreasureContent>
        <TreasureTitle>The Treasure</TreasureTitle>
        <TreasureItems>
          <TreasureItem>
            <TextSmall>
              At least
            </TextSmall>
            <TextLarge>
              5 ETH
            </TextLarge>
          </TreasureItem>
          <Plus>
            +
          </Plus>
          <TreasureItem>
            <TextLarge>
              100%
            </TextLarge>
            <TextSmall>
              OpenSea royalties
            </TextSmall>
          </TreasureItem>
          <Plus>
            +
          </Plus>
          <TreasureItem>
            <TextLarge>
              25%
            </TextLarge>
            <TextSmall>
              of all merch and comic sales
            </TextSmall>
          </TreasureItem>
          <Plus>
            +
          </Plus>
          <TreasureItem>
            <TextMedium>
              Panda NFTs
            </TextMedium>
            <TextSmall>
              and more...
            </TextSmall>
          </TreasureItem>
        </TreasureItems>
      </TreasureContent>
    </BambooFrame>
  )
}

export default Treasure;
