import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import bambooButton from "../../resources/img/home/cta-button.png";
import breakpoints from "../../styles/breakpoints";

const StyledButton = styled.a`
  background: url(${bambooButton}) no-repeat;
  background-size: auto 100%;
  border: 0;
  color: ${colors.black};
  ${fonts.gloryBold};
  font-size: 18px;
  border-radius: 0;
  text-align: center;
  width: 191px;
  height: 48px;
  line-height: 40px;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  text-decoration: none;

  &:hover {
    color: ${colors.darkGreen};
  }

  span {
    margin-top: -4px;
    padding-left: 4px;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 18px;
    width: 191px;
    height: 48px;
    line-height: 48px;
  }
`;

const ButtonLink = ({children, href, blank = true}) => {
  return (
    <StyledButton href={href} target={blank === true && "_blank"}>
      <span>
        {children}
      </span>
    </StyledButton>
  );
};

export default ButtonLink;
