import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import ButtonLink from "./ButtonLink";
import {Link} from "gatsby";
import LinkButton from "../Core/LinkButton";

const GetPassContainer = styled.div`
  max-width: 290px;
  text-align: center;
  margin: 0 auto 50px auto;
  
  a {
    margin: 20px auto 0 auto;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    max-width: 552px;
    margin-bottom: 105px;

    a {
      margin-top: 30px;
    }
  }
`;

const Title = styled.h2`
  ${fonts.gloryBold};
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 12px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 20px;
    font-size: 70px;
    line-height: 70px;
  }
`;

const Text = styled.p`
  ${fonts.gloryBold};
  font-size: 24px;
  line-height: 30px;
`;

const GetPass = () => {
  return (
    <GetPassContainer>
      <Title>
        Get your first Panda Pass
      </Title>
      <Text>
        To participate you must own at least one Panda NFT
      </Text>
      <ButtonLink href={"https://pandadynasty.io/login"} blank={false}>
        Start the Quest
      </ButtonLink>
    </GetPassContainer>
  )
}

export default GetPass;
