import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";

const HuntContainer = styled.div`
  max-width: 280px;
  text-align: center;
  margin: 0 auto 50px auto;
  
  @media (min-width: ${breakpoints.lg}) {
    max-width: 400px;
    margin-bottom: 105px;
  }
`;

const Title = styled.h2`
  ${fonts.gloryBold};
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 12px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 20px;
    font-size: 70px;
    line-height: 70px;
  }
`;

const Text = styled.p`
  ${fonts.gloryBold};
  font-size: 24px;
  line-height: 30px;
`;

const Hunt = () => {
  return (
    <HuntContainer>
      <Title>
        The hunt is on
      </Title>
      <Text>
        We’ve already released the pandas. Now it is your turn to play!
      </Text>
    </HuntContainer>
  )
}

export default Hunt;
