import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";
import H3 from "./H3";

const PassesListContainer = styled.div`
  max-width: 300px;
  margin: 0 auto 80px auto;

  @media (min-width: ${breakpoints.lg}) {
    max-width: 730px;
    margin-bottom: 100px;
  }
`;

const PassesListEntries = styled.div`
  border-left: 1px solid ${colors.white};
`;

const PassesListContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PassEntry = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-width: 280px;
  padding-left: 50px;
  margin-bottom: 27px;
  position: relative;
  
  &::before {
    content: "";
    width: 17px;
    height: 17px;
    border-radius: 50px;
    position: absolute;
    top: 23px;
    left: -9px;
    background: ${(props) => props.comingsoon === "true" ? "#92988c" : "white"};
    border: 1px solid ${(props) => props.comingsoon === "true" ? "#92988c" : "white"};
  }
  
  div {
    opacity: ${(props) => props.comingsoon === "true" ? 0.5 : 1};
  }
  
  @media (min-width: ${breakpoints.lg}) {
    max-width: 720px;
    padding-left: 60px;
    margin-bottom: 27px;

    &::before {
      top: 37px;
      left: -9px;
    }
  }
`;

const PassNumber = styled.div`
  ${fonts.gloryExtraBold};
  font-size: 42px;
  line-height: 60px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 50px;
    line-height: 90px;
  }
`;

const PassTitle = styled.div`
  ${fonts.gloryBold};
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 24px;
    line-height: 40px;
  }
`;

const PassText = styled.div`
  ${fonts.gloryRegular};
  font-size: 17px;
  line-height: 27px;
  
  a {
    color: ${colors.white};
    
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const PassesListIntro = styled.div`
  margin: 0 auto 40px auto;
  text-align: center;
  max-width: 552px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 32px;
  }
`;

const PassesList = () => {
  return (
    <PassesListContainer>
      <PassesListIntro>
        <H3>Panda Passes</H3>
      </PassesListIntro>
      <PassesListContent>
        <PassesListEntries>
          <PassEntry>
            <PassNumber>1</PassNumber>
            <PassTitle>Panda Dynasty NFT</PassTitle>
            <PassText><a href={"https://opensea.io/collection/pandadynasty"} target={"_blank"}>Grab your ticket</a> to proceed forward. By being a holder, the adventure begins.</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>2</PassNumber>
            <PassTitle>Magical Pumpkin</PassTitle>
            <PassText>Merge your panda NFT with matching <a href={"https://opensea.io/collection/panda-pumpkins"} target={"_blank"}>pumpkin</a> of your own. Only 8 colors will win. Click <a href={"https://pandadynasty.io/pumpkinswap"}>here</a> to see the list of the winning colors</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>3</PassNumber>
            <PassTitle>Comics unlock</PassTitle>
            <PassText>Thanks to <a href={"https://www.webtoons.com/en/challenge/panda-dynasty-age-of-the-dynasty/list?title_no=697468"} target={"_blank"}>clues</a> discovered in the comics you will <a href={"https://pandadynasty.io/pandapass"} target={"_blank"}>unlock a special NFT</a> that will help to open the treasure.</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>4</PassNumber>
            <PassTitle>Lucky Bamboo Video Game Episode 1</PassTitle>
            <PassText>Players that finish the first episode of the game will receive a Lucky Bamboo NFT. But hurry; you must <a href={"https://pandadynasty.io/startgame"} target={"_blank"}>resolve the puzzle</a> quickly to proceed forward.</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>5</PassNumber>
            <PassTitle>The Washing Machine</PassTitle>
            <PassText>Looks like we are ready to clean our accessories. <a href={"https://pandadynasty.io/startesr"} target={"_blank"}>Enter the foundry</a> for your accessory Panda Pass.</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>6</PassNumber>
            <PassTitle>Background Accessory</PassTitle>
            <PassText>Extract the accessory from your Panda NFT to create a Panda Pass.</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>7</PassNumber>
            <PassTitle>Time Machine Video Game Episode 2</PassTitle>
            <PassText>Be one of the first players to finish the <a href={"https://pandadynasty.io/startgame2"} target={"_blank"}>second episode of the game</a> and you’ll receive the time Machine NFT.</PassText>
          </PassEntry>
          <PassEntry>
            <PassNumber>8</PassNumber>
            <PassTitle>Bamboo Ticket</PassTitle>
            <PassText>Last NFT is the <a href={"https://pandadynasty.io/pt"} target={"_blank"}>Bamboo Ticket</a> that will give you a chance to win a part of the Bamboo treasure.</PassText>
          </PassEntry>
        </PassesListEntries>
      </PassesListContent>
    </PassesListContainer>
  )
}

export default PassesList;
