import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";
import buttonBackground from "../../resources/img/ui/button-background.png";

const StyledButton = styled.a`
  position: relative;
  display: block;
  clear: both;
  width: 144px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
  ${fonts.gloryBold};
  border: 0;
  color: ${colors.blue};
  text-transform: uppercase;
  cursor: pointer;
  background: url(${buttonBackground}) no-repeat;
  background-size: 100% auto;
  text-decoration: none;
  
  &:hover {
    color: ${colors.white};
  }
`;

const LinkButton = ({href, className, children}) => {
  return (
    <StyledButton href={href}
       className={className}
       target="_blank"
       rel="noopener noreferrer">
      {children}
    </StyledButton>
  );
};

export default LinkButton;
