import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import styled from "styled-components"
import Hero from "../components/Home/Hero";
import Container from "../components/Home/Container";
import Dynasty from "../components/Home/Dynasty";
import Specs from "../components/Home/Specs";
import Quest from "../components/Home/Quest";
import Hunt from "../components/Home/Hunt";
import Winners from "../components/Home/Winners";
import GetPass from "../components/Home/GetPass";
import PassesList from "../components/Home/PassesList";
import Treasure from "../components/Home/Treasure";
import Video from "../components/Home/Video";

const PageContent = styled.div`
`;

const IndexPage = ({location}) => {
  return (
    <Layout hero={"true"}>
      <Seo title={"Panda Dynasty | Join the bamboo"}
           description={"Deep within the Bamboo Forest, located in a far off corner of the digital world, an extraordinary treasure hunt awaits. Join today by purchasing at least one of our Panda Dynasty NFTs. And remember, X always marks the spot!"}
           path={location.pathname}/>
      <PageContent>
        <Hero />
        <Container>
          <Dynasty />
          <Video />
          <Hunt />
          <Quest />
          <Winners />
          <Treasure />
          <Specs />
          <PassesList />
          <GetPass />
        </Container>
      </PageContent>
    </Layout>
  )
};

export default IndexPage;
