import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";

const StyledH3 = styled.h3`
  ${fonts.gloryBold};
  font-size: 50px;
  line-height: 60px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 70px;
    line-height: 70px;
  }
`;

const H3 = ({children}) => {
  return (
    <StyledH3>
      {children}
    </StyledH3>
  )
}

export default H3;

