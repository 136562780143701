import * as React from "react"
import FrameImage from "../../resources/img/home/video-frame.png";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

const FrameContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin: 0 auto 72px auto;
  width: 100%;
  text-align: center;

  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 100px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = () => {
  return (
    <FrameContainer>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/hw4FYIRgtD4?rel=0"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen></iframe>
    </FrameContainer>
  )
}

export default Video;
