import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import {StaticImage} from "gatsby-plugin-image";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";

const TextImageContainer = styled.div`
  margin-bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 360px;
  margin-right: 0;
  margin-bottom: 72px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints.lg}) {
    margin-right: 120px;
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  width: 374px;
  height: 446px;
  position: relative;
  margin-bottom: 50px;

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;

    > div {
      height: 100%;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 552px;
    height: 100%;
    margin-bottom: 0;
  }
`;

const StyledH2 = styled.h2`
  ${fonts.gloryBold};
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 42px;
    line-height: 52px;
  }
`;

const StyledText = styled.p`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 32px;
  
  a {
    color: ${colors.white};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const StyledText2 = styled.p`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 0;
  
  a {
    color: ${colors.white};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const Quest = () => {
  return (
    <TextImageContainer>
      <TextContainer>
        <StyledH2>The Quest</StyledH2>
        <StyledText>
          Hidden in the Bamboo Forest there is a unique set of Panda Passes. Your mission, if you choose to accept it, is to collect each Panda Pass by successfully finding and solving ridiculously fun, sometimes difficult, always exciting games. You need these Panda-Passes to move forward with the hunt.
        </StyledText>
        <StyledH2>The Panda Passes</StyledH2>
        <StyledText2>
          From pumpkins to time machines and lucky bamboo NFTs, each Panda Pass has a specific goal, implementing more utility into the hunt.
        </StyledText2>
      </TextContainer>
      <ImageContainer>
        <StaticImage
          src={"../../resources/img/home/map.jpg"}
          alt={"Quest map"}
          objectFit={"contain"}
        />
      </ImageContainer>
    </TextImageContainer>
  )
}

export default Quest;
