import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";
import colors from "../../styles/colors";

const StyledText = styled.p`
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 12px;
  
  a {
    color: ${colors.white};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

const Text = ({children}) => {
  return (
    <StyledText>
      {children}
    </StyledText>
  )
}

export default Text;

