import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import {StaticImage} from "gatsby-plugin-image";
import H2 from "./H2";
import Text from "../Core/Text";
import ButtonLink from "./ButtonLink";

const TextImageContainer = styled.div`
  margin-bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  margin-left: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 120px;
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  width: 373px;
  height: 295px;
  margin-bottom: 17px;

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
    
    > div {
      height: 100%;
    }
  }
  
  @media (min-width: ${breakpoints.lg}) {
    width: 552px;
    height: 435px;
    margin-bottom: 0;
  }
`;

const Winners = () => {
  return (
    <TextImageContainer>
      <ImageContainer>
        <StaticImage
          src={"../../resources/img/home/chest-large.png"}
          alt={"Winners"}
          objectFit={"contain"}
        />
      </ImageContainer>
      <TextContainer>
        <H2>The Winners</H2>
        <Text>
          Being part of our “only for panda holders” discord channel, THE BAMBOO, you will be able to find each Panda Pass and game. The treasure is unlocked with each step moved forward and the first players to collect all will have a chance at the ultimate prize!
        </Text>
        <ButtonLink href={"https://discord.gg/7U5mJXgNPW"}>
          Join Discord
        </ButtonLink>
      </TextContainer>
    </TextImageContainer>
  )
}

export default Winners;
