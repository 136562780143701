import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";
import fonts from "../../styles/fonts";

const StyledH2 = styled.h2`
  ${fonts.gloryBold};
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.lg}) {
    font-size: 42px;
    line-height: 52px;
  }
`;

const H2 = ({children}) => {
  return (
    <StyledH2>
      {children}
    </StyledH2>
  )
}

export default H2;

