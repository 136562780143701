import * as React from "react"
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import {StaticImage} from "gatsby-plugin-image";
import H2 from "./H2";
import Text from "../Core/Text";
import ButtonLink from "./ButtonLink";

const TextImageContainer = styled.div`
  margin-bottom: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: -60px;
  
  @media (min-width: ${breakpoints.lg}) {
    flex-direction: row;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 360px;
  margin-left: 0;

  @media (min-width: ${breakpoints.md}) {
    padding-top: 32px;
  }
  
  @media (min-width: ${breakpoints.lg}) {
    margin-left: 120px;
    margin-bottom: 0;
  }
`;

const ImageContainer = styled.div`
  width: 272px;
  height: 285px;
  margin-bottom: 16px;

  .gatsby-image-wrapper {
    width: 100% !important;
    height: 100% !important;
    
    > div {
      height: 100%;
    }
  }
  
  @media (min-width: ${breakpoints.lg}) {
    width: 546px;
    height: 540px;
    margin-bottom: 0;
  }
`;

const Dynasty = () => {
  return (
    <TextImageContainer>
      <ImageContainer>
        <StaticImage
          src={"../../resources/img/home/panda-passes.png"}
          alt={"Panda Passes"}
          objectFit={"contain"}
        />
      </ImageContainer>
      <TextContainer>
        <H2>8 Panda Passes are hidden and waiting</H2>
        <Text>
          Deep within the Bamboo Forest, located in a far off corner of the digital world, an extraordinary treasure hunt awaits. Join today by purchasing at least one of our Panda Dynasty NFTs secured on Ethereum Blockchain. And remember, X always marks the spot!
        </Text>
        <ButtonLink href={"https://pandadynasty.io/login"} blank={false}>
          Start the Quest
        </ButtonLink>
      </TextContainer>
    </TextImageContainer>
  )
}

export default Dynasty;
