import * as React from "react"
import styled from "styled-components"

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: 1040px;
  margin: auto;
  padding: 0 40px;
`;

const Container = ({children}) => {
  return (
    <StyledContentContainer>
      {children}
    </StyledContentContainer>
  )
};

export default Container;

