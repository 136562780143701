import * as React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints";
import SocialLinks from "../Nav/SocialLinks";
import {Link} from "gatsby";
import colors from "../../../styles/colors";
import activeNavLink from "../../../resources/img/ui/nav-link-background.png";
import fonts from "../../../styles/fonts";
import {useEffect, useState} from "react";
import navBackground from "../../../resources/img/bamboo-panels/nav-background.png";
import navBackgroundMobile from "../../../resources/img/bamboo-panels/nav-background-mobile.png";
import ExternalLink from "../ExternalLink";
import {setBodyOverflow} from "../../../services/browser";

const NavContainer = styled.div`
  width: 100%;
  max-width: 375px;
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto 42px auto;
  background: url(${navBackgroundMobile}) no-repeat;
  background-size: 100% auto;
  position: relative;
  z-index: 88888;

  @media (min-width: ${breakpoints.xl}) {
    justify-content: space-between;
    max-width: 1145px;
    height: 90px;
    margin: 0 auto 12px auto;
    background: url(${navBackground}) no-repeat;
    background-size: 100% auto;
  }
`;

const NavContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${breakpoints.xl}) {
    width: 904px;
    height: 64px;
    margin: auto;
  }
`;

const MobileNav = styled.button`
  border: 0;
  background: transparent;
  text-transform: uppercase;
  width: 144px;
  margin-top: 20px;
  height: 34px;
  line-height: 34px;
  font-size: 22px;
  text-decoration: none;
  color: ${colors.black};
  ${fonts.gloryBold};
  cursor: pointer;
  
  &:hover {
    opacity: 0.7;
  }

  @media (min-width: ${breakpoints.xl}) {
    display: none;
  }
`;

const MobileNavBackground = styled.div`
  position: fixed;
  display: ${(props) => props.open ? "block" : "none"};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.black};
  z-index: 8880;
`;

const NavItems = styled.div`
  display: ${(props) => props.open ? "flex" : "none"};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 8888;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 152px;
  
  text-decoration: none;

  a {
    text-transform: uppercase;
    height: 72px;
    line-height: 72px;
    font-size: 24px;
    text-decoration: none;
    color: ${colors.white};
    ${fonts.gloryBold};

    &.active,
    &:hover {
      opacity: 0.7;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    position: relative;
    width: auto;
    top: unset;
    left: unset;
    margin-top: 0;

    a {
      color: ${colors.black};
      font-size: 16px;
      height: 34px;
      line-height: 34px;
      width: 129px;
      margin-right: 0;
    }
  }
`;

const Nav = () => {
  const [currentHover, setCurrentHover] = useState(0);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  useEffect(() => {
    setBodyOverflow(isMobileNavOpen);
  }, [isMobileNavOpen])

  return (
    <>
      <NavContainer>
        <NavContent>
          <MobileNav onClick={(e) => setIsMobileNavOpen(!isMobileNavOpen)}>
            {isMobileNavOpen ? `Close` : `Menu`}
          </MobileNav>
          <NavItems open={isMobileNavOpen}>
            <Link to={"/"} activeClassName={(!currentHover) ? "active" : "out"}
                  onMouseEnter={(e) => setCurrentHover(1)}
                  onMouseLeave={(e) => setCurrentHover(0)}>Home</Link>
            <Link to={"/login"} activeClassName={(!currentHover) ? "active" : "out"}
                  onMouseEnter={(e) => setCurrentHover(2)}
                  onMouseLeave={(e) => setCurrentHover(0)}>Quest</Link>
            <Link to={"/comics"} activeClassName={(!currentHover) ? "active" : "out"}
                  onMouseEnter={(e) => setCurrentHover(3)}
                  onMouseLeave={(e) => setCurrentHover(0)}>Comics</Link>
            <Link to={"/merch"} activeClassName={(!currentHover) ? "active" : "out"}
                  onMouseEnter={(e) => setCurrentHover(4)}
                  onMouseLeave={(e) => setCurrentHover(0)}>Merch</Link>
            <Link to={"/news"} activeClassName={(!currentHover) ? "active" : "out"}
                  onMouseEnter={(e) => setCurrentHover(6)}
                  onMouseLeave={(e) => setCurrentHover(0)}>News</Link>
            <ExternalLink href={"https://cg.pandadynasty.io/"} activeClassName={(!currentHover) ? "active" : "out"}
                  onMouseEnter={(e) => setCurrentHover(7)}
                  onMouseLeave={(e) => setCurrentHover(0)}>Game</ExternalLink>
          </NavItems>
          <SocialLinks />
        </NavContent>
      </NavContainer>
      <MobileNavBackground open={isMobileNavOpen} />
    </>
  )
}

export default Nav;
